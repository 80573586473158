import React, {useState} from 'react'
// import Redevlogo  from '../../../assets/Redevlogo.png'
import file1 from '../../../assets/LOGO/file1.png'
import file2 from '../../../assets/LOGO/file2.png'
import {useLocation} from 'react-router-dom'

import GradiantUnderline from '../GradiantUnderline/GradiantUnderline'
import Redevlogo from '../../../assets/img/Redevlogo'


import {useTranslation} from 'react-i18next'
import AppleStoreIcon from "../../../assets/icons/AppleStoreIcon";
import GooglePlayIcon from "../../../assets/icons/GooglePlayIcon";
import GooglePlayIconFooter from "../../../assets/icons/GooglePlayIconFooter";
import AppStoreIconFooter from "../../../assets/icons/AppStoreIconFooter";
import ImprintModal from "../ImprintModal/ImprintModal";
import LinkedinIco from "../../../assets/icons/LinkedinIco";
import TweeterIco from "../../../assets/icons/TweeterIco";
import TelegramIco from "../../../assets/icons/TelegramIco";
import InstaIco from "../../../assets/icons/InstaIco";

const Footer = () => {
    const location = useLocation()

    const [openImprintModal, setOpenImprintModal] = useState<boolean>(false)

    console.log('imprint', openImprintModal)


    const {t, i18n} = useTranslation()
    return (
        <div className='h-[19rem]  bg-[#f3f4f6] lg:h-full'>
            <div className=' max-w-7xl mx-auto grid grid-cols-12  '>

                <div className='col-span-4 md:col-span-12 flex flex-col justify-around pt-6 items-center'>
                    {/*<div className=''>*/}
                        <Redevlogo className="w-full h-40 sm:h-32"/>
                            {/*className='minmd:h-[10rem] md:h-[10rem] md:w-[26rem]  lg:w-[22rem] minlg:w-[26rem]'/>*/}
                    {/*</div>*/}

                    <div className='w-full grid grid-cols-12 px-2 gap-x-4'>
                        <a className="col-span-3 flex place-content-center" target='_blank' href="https://www.linkedin.com/company/redev-austria">
                            <LinkedinIco className='md:scale-100 lg:scale-75'/>
                        </a>
                        <a className="col-span-3 flex place-content-center" target='_blank' href="https://t.me/redevjobs">
                            <TelegramIco className=' md:scale-100 lg:scale-75'/>
                        </a>
                        <a className="col-span-3 flex place-content-center" target='_blank' href="https://www.instagram.com/redevaustria">
                            <InstaIco className=' md:scale-100 lg:scale-75'/>
                        </a>
                        <a className="col-span-3 flex place-content-center" target='_blank' href="https://twitter.com/redevaustria">
                            <TweeterIco className=' md:scale-100 lg:scale-75'/>
                        </a>
                    </div>
                </div>


                <div className='col-span-2 gap-y-4 md:col-span-12 grid place-content-center pt-6 md:hidden'>
                    <a href={"/redev-privacy-policy.pdf"} download={true}>
                        <img className='' src={file1} alt=''/>
                    </a>
                    <img onClick={() => setOpenImprintModal(true)} className='cursor-pointer' src={file2} alt=''/>
                </div>

                <div className="col-span-6 md:col-span-12 grid grid-cols-12">

                    <div className='col-span-8 md:col-span-12 md:px-4 grid grid-cols-2 gap-y-2sm:px-8 minlg:gap-y-10 mt-6 '>

                        <div className='col-span-2 md:col-span-1 space-y-4 w-full md:items-center md:justify-between '>
                            <div className='font-semibold'>
                                <p className="font-semibold text-sm lg:text-xs"> {t<string>("For Candidates")}</p>
                                <GradiantUnderline w="minlg:w-[300px] lg:w-[40px]" isVisible={true}/>
                            </div>
                            <div className='flex flex-row lg:grid lg:grid-cols-12 gap-x-2 md:hidden'>
                                <a className='lg:col-span-6 flex place-content-center' href="https://apps.apple.com/at/app/redev/id1633895339?l=en" target="_blank">
                                    <AppleStoreIcon/>
                                </a>
                                <a className='lg:col-span-6 flex place-content-center' href="https://play.google.com/store/apps/details?id=at.redev.android" target="_blank">
                                    <GooglePlayIcon/>
                                </a>
                                {/*<WebAppIcon/>*/}
                            </div>
                            <div className='flex flex-col  gap-y-2 minmd:hidden'>
                                <a href="https://apps.apple.com/at/app/redev/id1633895339?l=en" target="_blank">
                                    <AppStoreIconFooter/>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=at.redev.android" target="_blank">
                                    <GooglePlayIconFooter/>
                                </a>
                                {/*<WebAppIconFooter/>*/}
                            </div>
                        </div>


                        <div className='col-span-2 md:col-span-1 w-full flex flex-col justify-start gap-y-4 md:items-center md:justify-start '>
                            <div className='font-semibold w-full flex flex-col justify-start items-start'>
                                <p className="text-sm lg:text-xs"> {t<string>("For Companies")}</p>
                                <GradiantUnderline w="minlg:w-[300px] lg:w-[40px] sm:mr-[78px]" isVisible={true}/>
                            </div>
                            <div className='w-full flex flex-row justify-start items-center lg:grid lg:grid-cols-12 md:flex md:flex-col md:justify-start md:items-start md:gap-y-2 px-1 gap-x-2'>
                                <a className='bg-yellow-500  lg:col-span-6 flex place-content-center' target="_blank" href="https://dashboard.redev.jobs">
                                    <button type='button'
                                            className='md:px-2 minlg:px-2 minlg:min-w-[120px] bg-black rounded-md lg:w-full py-2 font-semibold text-sm text-white '>{t<string>('get_started')}
                                    </button>
                                </a>
                                <a className='lg:col-span-6 flex place-content-center' target="_blank" href="https://dashboard.redev.jobs/request-demo">
                                    <button type='button'
                                            className='md:px-2  minlg:px-2 minlg:min-w-[120px] bg-black rounded-md lg:w-full py-2 font-semibold text-sm text-white whitespace-nowrap '>
                                        {t<string>('try_free_demo')}
                                    </button>
                                </a>
                            </div>
                        </div>

                    </div>

                    <div className='minmd:hidden md:col-span-6 mt-10 px-4'>
                        <p className='text-[#4a4a4a] text-xs text-start leading-5'>
                            {t<string>('footer_redev_info')}
                        </p>
                    </div>

                    <div className='col-span-4 md:col-span-6'>
                        <div className='space-y-3 mt-6 md:text-center px-2 md:order-2 flex flex-col justify-start items-start'>
                            <div className='font-semibold'>
                                <p className="text-sm lg:text-xs">{t<string>("Address")}</p>
                                <div className='flex md:justify-center sm:w-full'>
                                    <GradiantUnderline w={'w-20'} isVisible={true}/></div>
                            </div>
                            <div className='space-y-3 text-sm lg:text-xs flex flex-col justify-start items-start text-start'>
                                <p className='font-semibold'> ReDev GmbH</p>
                                <p className=''>Strozzigasse 32-34/1/7 1080 Vienna, Austria</p>
                                <p><a className=""
                                      href="tel:+4317071949">+43-1-7071949</a></p>
                                <div>
                                    <a className='text-[#3869F1] ' href="mailto:hello@redev.at">hello@redev.at</a>
                                </div>
                            </div>
                            {/* <p className='minmd:hidden ml-5 mt-6 text-xs leading-[20px] text-left w-[10rem]'>{location.pathname === '/candidates' ? "" : "ReDev's social media job app is an innovative platform for every talent in the world to grow, develop, showcase their skills, and find their dream job in a secure, equal, and efficient environment."}</p> */}

                        </div>
                    </div>

                </div>

            </div>
            <hr className='mt-5'/>
            <div className='mt-4 minmd:hidden w-full justify-center items-center text-[#4a4a4a]'>
                <div className="flex flex-row gap-x-4 items-center justify-center">
                    <p className='uppercase text-xs cursor-pointer' onClick={() => setOpenImprintModal(true)}>
                        {t<string>('Imprint')}
                    </p>
                    <a className='uppercase  text-xs decoration-0' href={"/redev-privacy-policy.pdf"} download={true}>
                        {t<string>('PrivacyPolicy')}
                    </a>
                </div>
            </div>
            <div className='sm:pt-3 sm:pb-7 py-4'>
                <p className='text-center text-sm lg:text-xs text-[#6b7280] md:mx-4 leading-[22px]  '>{t<string>("last")}</p>
            </div>
            <ImprintModal open={openImprintModal} onClose={() => setOpenImprintModal(false)}/>

        </div>
    )
}

export default Footer
