import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";

import TrustedBy from "../../../../common/components/TrustedBy/TrustedBy";
import WatchVideoVector from "../../../../assets/icons/WatchVideoVector";
import TrialVector from "../../../../assets/icons/TrialVector";
import image from '../../../../assets/img/VideoImage.png'
import PlayerModal from "../../../../Components/PlayerModal/PlayerModal";

const FirstView = () => {
    const {t} = useTranslation();

    const [showPlayer, setShowPlayer] = useState<boolean>(false)

    return (
        <div id="download" className='w-full bg-gradient-to-r from-[#EEF2FF]  to-[#F6F8FD]'>
            <div
                className="relative flex flex-col  minlg:mt-6  pb-32  relative  minlg:rounded-2xl md:items-center md:justify-center max-w-7xl mx-auto ">
                <div
                    className="flex xl:flex-col lg:ml-6 lg:mr-6 sm:justify-center minxl:flex-row  minmd:justify-center mb-10  min2xl:mx-0 minxl:mx-8 pt-14 justify-between">
                    <div className='w-full flex flex-col items-start gap-y-4'>
                        <div className="w-full lg:pt-10">
                            <div className="text-[45px] sm:text-xl  sm:leading-10 font-semibold md:w-full">
                                {/*<Trans i18nKey="first_view_com_title">*/}
                                {/*    The AI-Powered HR Platform: <br/><span*/}
                                {/*    className='text-white bg-[#3869F1] rounded-[7px] px-2 w-full'>AI-Powered</span>*/}
                                {/*</Trans>*/}
                                <p>
                            <span className='text-white bg-[#3869F1] rounded-[7px] px-2 w-full'>
                                {t<string>('AiPowered')}
                            </span>
                                    {t<string>('AiPowered2')}
                                </p>
                                <p className='text-[#555555] text-[32px] sm:text-base'>
                                    {t<string>('AiPowered3')}
                                </p>
                            </div>
                        </div>
                        <div className="flex lg:flex-row gap-x-4 justify-between md:flex-col w-full">
                            <div
                                className="md:space-y-20 sm:space-y-6 minlg:space-y-10 lg:space-y-8 md:order-2 lg:w-1/3 md:w-full">
                                <div
                                    className=" text-md mt-10 leading-10  minlg:text-justify lg:w-[60%] md:w-[90%] minlg:w-[70%] text-[#555555] font-light md:text-sm whitespace-pre-wrap">
                                    {t<string>('FASTER')}<br/>
                                    {t<string>('CHEAPER')}<br/>
                                    {t<string>('FLAWLESS')}<br/>
                                </div>
                                <div
                                    className="relative flex flex-col gap-y-4 lg:flex-col md:flex-row  md:w-full md:justify-center md:items-center md:gap-x-2 md:order-3">
                                    <a href='https://dashboard.redev.jobs' target={"_blank"} className='md:w-1/2'>
                                        <button
                                            type="submit"
                                            className=" cursor-pointer bg-[#3869F1] rounded-[8px] md:rounded-[4px] text-xl font-extrabold text-white  minlg:min-w-[20rem] lg:w-full md:text-sm md:w-full lg:py-3 py-5"
                                        >
                                            {t<string>("post_a_job")}
                                        </button>
                                    </a>
                                    <a href='https://dashboard.redev.jobs/request-demo' target={"_blank"}
                                       className='md:w-1/2'>
                                        <button
                                            type="submit"
                                            className=" cursor-pointer bg-black rounded-[8px] md:rounded-[4px]  text-white  text-xl font-extrabold text-white  minlg:min-w-[20rem] lg:w-full  md:w-full md:text-sm lg:py-3  py-5 whitespace-nowrap"
                                        >
                                            {t<string>("demo_btn")}
                                        </button>
                                    </a>
                                    <TrialVector className='absolute -bottom-16 left-2/3'/>
                                    <div
                                        className='absolute -bottom-24 left-36 bg-[#FFEDED] text-[#D80303] px-2 w-fit rounded-[6px] whitespace-nowrap md:text-sm md:-bottom-20'>
                                        {t<string>('TryTrial')}
                                    </div>
                                </div>
                            </div>
                            <div
                                className='relative w-[60%] md:w-full lg:w-[70%] lg:flex lg:items-end lg:justify-center'>
                                <img src={image} alt='' className='w-full object-cover aspect-video cursor-pointer'
                                     onClick={() => setShowPlayer(true)}
                                />

                                <WatchVideoVector className='absolute right-1/3 -top-1/4 md:hidden lg:-top-6'/>
                                <div
                                    className='absolute right-28 lg:right-[18%] -top-1/4 lg:-top-[10%] bg-[#FFEDED] text-[#D80303] px-2 w-fit rounded-[6px] md:hidden'>
                                    {t<string>('WatchVideo')}
                                </div>

                            </div>
                            {/*<div className="flex lg:justify-end md:justify-center xl:justify-end ">*/}
                            {/*    <Cards/>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>
                <TrustedBy/>
                <PlayerModal show={showPlayer} videoUrl={'https://redev.jobs/redev-video.mp4'}
                             closeModalFn={() => setShowPlayer(false)}/>
                {/* <div  className='bg-white shadow-md shadow-slate-100 w-[80%]  md:h-[5rem]  h-26 mx-auto rounded-2xl absolute -bottom-16 left-32 md:left-10 h-[9rem]'>
           <p className='text-[#9ca3af] text-lg md:text-xs  mt-2 text-center'>{t<string>('TRUSTED BY')}</p>
           <div className='flex items-center justify-around md:mx-2 md:-translate-y-4 '>
                    */
                }

                {/* <div  className='bg-white shadow-md shadow-slate-100 w-[80%]  md:h-[5rem]  h-26 mx-auto rounded-2xl absolute -bottom-16 left-32 md:left-10 h-[9rem]'>
           <p className='text-[#9ca3af] text-lg sm:text-xs  mt-2 text-center'>{t<string>('TRUSTED BY')}</p>
           <div className='flex items-center justify-around sm:mx-2 sm:-translate-y-4 '>
                       <img src={CompanyLogo2} className='md:w-[5rem]' alt="" />
                       <img src={CompanyLogo4} className='md:w-[5rem]' alt="" />
                       <img src={CompanyLogo5} className='md:w-[5rem]' alt="" />
                       <img src={CompanyLogo4} className='md:w-[5rem]' alt="" />
               </div>
           </div>
      */
                }
            </div>
        </div>
    )
        ;
};

export default FirstView;
