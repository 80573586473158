import React, {useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Oval} from "react-loader-spinner";
import {useTranslation} from "react-i18next";
import Tick from '../../../assets/img/Tick'

const schema = yup.object().shape({
    email: yup.string().email("invalidEmail").required("requiredEmail"),
});


const Subscribe = () => {
    const {t} = useTranslation();


    const {register, handleSubmit, formState: {errors}, setError} = useForm({
        resolver: yupResolver(schema)
    })


    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubscribe = (data: any) => {

        setLoading(true);
        axios
            .post("https://api.redev.jobs/landing/newsletter/subscribe", data)
            .then((res) => {
                console.log(res);
                if (!res.data.success) {
                    setError("email", {
                        type: "custom",
                        message: res.data.code,
                    });
                    return;
                }
                setSubscribed(true);
            })
            .catch((err) => {
                console.log(err);
                setError("email", {
                    type: "custom",
                    message: "newsletter_unknown_error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className=" flex my-16 sm:h-52 max-w-7xl mx-auto sm:mb-6 lg:px-4 minmd:px-4 ">
            <div
                className="w-full bg-[#eef2ff] py-8 sm:py-4 sm:w-full sm:h-52 md:py-5 minsm:rounded-[20px]  sm:max-w-lg ">
                <div
                    className="flex flex-row h-full sm:w-full items-center justify-between sm:flex-col sm:gap-y-4  minmd:gap-x-4 minmd:px-4">
                    <div className="w-1/2 sm:w-full whitespace-nowrap md:px-2 minmd:w-fit">
                        <h4 className="text-2xl lg:text-xl sm:text-[18px]  text-[#1B2A3E] sm:text-center">
                            {t<string>("substitle")}
                        </h4>
                    </div>
                    <div className="w-1/2 sm:w-full lg:w-2/3 sm:h-full flex justify-center sm:mt-0 ">
                        <div className="w-full sm:px-6 sm:h-full">
                            {!subscribed ? (
                                <>
                                    <form onSubmit={handleSubmit(onSubscribe)}
                                          className='bg-white flex minmd:py-2 relative items-center justify-between rounded-[10px] pr-1'>
                                        <input
                                            {...register('email')}
                                            type="text"
                                            className='rounded-[10px] sm:rounded-[10px] sm:text-center sm:w-full sm:py-4 w-[30rem] minlg:w-[36rem] lg:w-4/6 px-4 outline-none  text-md lg:text-lg sm:text-xs md:text-sm  sm:font-normal sm:text-left '
                                            placeholder={t<string>('emailInput')}
                                        />
                                        <button
                                            type="submit"
                                            className="px-8 py-4 minlg:py-4 md:py-3 bg-[#3869F1] sm:absolute rounded-[10px] sm:rounded-[10px]  text-white  text-md lg:text-lg md:text-sm sm:font-semibold py-2 -bottom-[4.5rem] sm:w-[100%] sm:-bottom-16"
                                        >

                                            {loading ? (
                                                <div className="mx-auto w-fit h-fit ">
                                                    <Oval
                                                        height={30}
                                                        width={30}
                                                        color="#ffffff"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel="oval-loading"
                                                        secondaryColor="#4fa94d"
                                                        strokeWidth={5}
                                                        strokeWidthSecondary={5}
                                                    />
                                                </div>
                                            ) : (
                                                t<string>("subscribe")
                                            )}
                                        </button>

                                    </form>
                                    <p className="text-red-500 mx-auto text-left max-w-4xl sm:w-full">
                                        {t<string>(errors.email?.message?.toString() ?? "")}
                                    </p>
                                </>
                            ) : (
                                <div
                                    className="bg-[#232323] rounded-2xl text-white flex justify-center items-center  sm:gap-x-2 mx-auto py-3 max-w-2xl px-6  sm:mt-14">
                                    <Tick className='sm:w-6  h-6'/>
                                    <p className=' text-xl sm:text-sm'>{t<string>('Subscribe_Success')}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;
