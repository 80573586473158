import React, {useEffect, useState} from 'react'
import discover from '../../../../assets/discover.png'
import {useTranslation} from "react-i18next"
import quickPic1 from '../../../../assets/quickPic1.png'
import quickPic2 from '../../../../assets/quickPic2.png'
import quickStep3 from '../../../../assets/quickStep3.gif'
import quickPic4 from '../../../../assets/quickPic4.png'

import GuideSlider from './GuideSlider'
import circle from '../../../../assets/circle.png'
import RightArrow from "../../../../assets/icons/RightArrow";
import CurveArrow from "../../../../assets/icons/CurveArrow";
import Cards from "../FirstView/Cards";

const QuickGuide = () => {
    // const width = window.innerWidth
    const breakpoint = 757
    const {t} = useTranslation();
    const [width, setWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth))
    }, []);

    // create state for image 

    const [image, setImage] = useState(discover)
    const [selected, setSelected] = useState<boolean>(false)

    const [cardOneActive, setCardOneActive] = useState<boolean>(true)
    const [cardTwoActive, setCardTwoActive] = useState<boolean>(false)
    const [cardThreeActive, setCardThreeActive] = useState<boolean>(false)
    const [cardFourActive, setCardFourActive] = useState<boolean>(false)
    const [cardFiveActive, setCardFiveActive] = useState<boolean>(false)


//create onclick function that  change the image state 

    const onClickImagesChange = (cardname: string, newImage: string) => {
        setImage(newImage)
        if (cardname === "cardone") {
            setCardOneActive(true)
            setCardTwoActive(false)
            setCardThreeActive(false)
            setCardFourActive(false)
            setCardFiveActive(false)
        } else if (cardname === "cardtwo") {
            setCardOneActive(false)
            setCardTwoActive(true)
            setCardThreeActive(false)
            setCardFourActive(false)
            setCardFiveActive(false)
        } else if (cardname === "cardthree") {
            setCardOneActive(false)
            setCardTwoActive(false)
            setCardThreeActive(true)
            setCardFourActive(false)
            setCardFiveActive(false)
        } else if (cardname === "cardfour") {
            setCardOneActive(false)
            setCardTwoActive(false)
            setCardThreeActive(false)
            setCardFourActive(true)
            setCardFiveActive(false)
        } else {
            setCardOneActive(false)
            setCardTwoActive(false)
            setCardThreeActive(false)
            setCardFourActive(false)
            setCardFiveActive(true)
        }
    }


    return width < breakpoint ? <GuideSlider/> : (
        <div className='bg-[#eef2ff]'>
            <div className=' pt-10 max-w-7xl mx-auto'>
                <div className='flex flex-row items-center justify-between mx-6'>
                    <div className='w-[50%]  space-y-10'>
                        <h1 className=" text-3xl font-semibold  leading-[48px]  ">{t<string>("guidetitle")}</h1>
                        <p className='text-sm font-light   text-justify leading-relaxed  minlg:w-[89%]   text-[#424242]'>{t<string>("secondText")}</p>
                        <div className='flex gap-x-4 gap-y-4 lg:flex-col'>
                            <a href='https://dashboard.redev.jobs/' target={"_blank"}>
                                <button
                                    className="  bg-[#3869F1] rounded-[8px] min-w-[237px]  text-white px-10  py-2   text-[22px]  "
                                > {t<string>("get started")}</button>
                            </a>
                            <a href='https://dashboard.redev.jobs/request-demo' target={"_blank"}>
                                <button
                                    type="submit"
                                    className=" bg-black rounded-[8px] min-w-[237px] text-white px-8 py-2 text-[22px] whitespace-nowrap "
                                > {t<string>("demo_btn")}</button>
                            </a>
                        </div>
                    </div>
                    <div className="flex lg:justify-end md:justify-center xl:justify-end  scale-75 lg:ml-48 ">
                        <Cards/>
                    </div>
                    {/*<img className='w-1/2 object-contain object-center' alt="" src={image}/>*/}
                </div>
            </div>

            <div className='pt-10 flex max-w-7xl mx-auto justify-center relative'>
                <div onClick={() => onClickImagesChange("cardone", quickPic1)}
                     className={`${cardOneActive ? "bg-[#3869F1] " : " bg-white"} shadow-xl  hover:bg-[#3869F1]  cursor-pointer group group-hover:text-white  shadow-[#DAE4FB]  rounded-lg flex flex-col   h-[260px] lg:h-[320px]  space-y-5 w-1/3   lg:p-5  minlg:p-8`}>

                    <div className=' flex space-x-2'>
                        <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-md minlg:h-[46px] text-2xl text-[#3869F1]">1</p>
                        <h1 className={`${cardOneActive ? "text-white  " : " text-[#3869F1]"} minlg:text-xl lg:text-md  group-hover:text-white flex items-center `}>{t<string>("StartsEasilyItem_1_title")}</h1>
                    </div>

                    <p className={`${cardOneActive ? "text-white  " : " text-[#3869F1]"} text-sm  font-light tracking-wide text-left leading-relaxed text-[#424242] group-hover:text-white `}> {t<string>("StartsEasilyItem_1_text")}</p>
                </div>

                <RightArrow className="h-4 mt-32 lg:h-2 "/>
                <div onClick={() => onClickImagesChange("cardtwo", quickPic2)}
                     className={`${cardTwoActive ? "bg-[#3869F1]" : " bg-white"} shadow-xl shadow-[#DAE4FB] flex flex-col cursor-pointer group hover:bg-[#3869F1] rounded-lg space-y-5 w-1/3 h-[260px] lg:h-[320px]  bg-white   lg:p-5  minlg:p-8`}>

                    <div className='flex space-x-2'>

                        <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-md minlg:h-[46px] text-2xl  text-[#3869F1]">2</p>
                        <h1 className={`${cardTwoActive ? "text-white  " : " text-[#3869F1]"} minlg:text-xl lg:text-md group-hover:text-white  text-[#3869F1] flex items-center`}>{t<string>("StartsEasilyItem_2_title")}</h1>
                    </div>

                    <p className={`${cardTwoActive ? "text-white  " : " text-[#3869F1]"} text-sm font-light tracking-wide group-hover:text-white  text-left leading-relaxed text-[#424242] `}> {t<string>("StartsEasilyItem_2_text")}</p>
                </div>
                <div>
                    <img className="absolute lg:h-40 lg:top-[90%] top-40" alt="" src={circle}/>
                </div>

            </div>

            <div className='pt-10 flex  justify-center max-w-7xl mx-auto  relative'>
                <div>
                    <CurveArrow
                        className="absolute lg:-left-2 lg:scale-50 lg:top-52 min2xl:left-11 right-[88%] top-40"/>
                </div>

                <div onClick={() => onClickImagesChange("cardfour", quickPic4)}
                     className={`${cardFourActive ? "bg-[#3869F1]" : " bg-white"} shadow-xl flex flex-col   shadow-[#DAE4FB] group cursor-pointer hover:bg-[#3869F1]  rounded-lg space-y-5 w-1/3 h-[260px]  lg:h-[320px]  bg-white    lg:p-5  minlg:p-8`}>
                    <div className='flex space-x-2'>
                        <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-[4px] minlg:h-[46px] text-2xl  text-[#3869F1]"> 4</p>
                        <h1 className={`${cardFourActive ? "text-white  " : " text-[#3869F1]"} minlg:text-xl lg:text-md group-hover:text-white flex items-center  text-[#3869F1]`}>{t<string>("StartsEasilyItem_4_title")}</h1>
                    </div>

                    <p className={`${cardFourActive ? "text-white  " : " text-[#3869F1]"} text-sm font-light group-hover:text-white  tracking-wide text-left leading-relaxed  text-[#424242] `}> {t<string>("StartsEasilyItem_4_text")}</p>
                </div>

                <RightArrow className="h-4 mt-32 lg:h-2 rotate-180 "/>
                <div onClick={() => onClickImagesChange("cardthree", quickStep3)}
                     className={`${cardThreeActive ? "bg-[#3869F1]" : " bg-white"} shadow-xl flex flex-col  shadow-[#DAE4FB] group cursor-pointer  hover:bg-[#3869F1] rounded-lg space-y-5 w-1/3 h-[260px]  lg:h-[320px] bg-white    lg:p-5  minlg:p-8 `}>

                    <div className='flex space-x-2'>
                        <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-[4px] minlg:h-[46px] text-2xl  text-[#3869F1]"> 3</p>
                        <h1 className={`${cardThreeActive ? "text-white  " : " text-[#3869F1]"} minlg:text-xl lg:text-md group-hover:text-white flex items-center text-[#3869F1]`}>{t<string>("StartsEasilyItem_3_title")}</h1>
                    </div>

                    <p className={`${cardThreeActive ? "text-white  " : " text-[#3869F1]"}  text-sm font-light tracking-wide text-left  group-hover:text-white  leading-relaxed  text-[#424242] `}>{t<string>("StartsEasilyItem_3_text")}</p>
                </div>


            </div>

            <div className='pt-10 flex  justify-center max-w-7xl mx-auto pb-10'>
                <div onClick={() => onClickImagesChange("cardfive", discover)}
                     className={`${cardFiveActive ? "bg-[#3869F1]" : " bg-white"} shadow-xl flex flex-col   group hover:bg-[#3869F1] shadow-[#DAE4FB] rounded-lg space-y-5 w-1/3 h-[260px]  lg:h-[320px] cursor-pointer bg-white   lg:p-5  minlg:p-8 `}>

                    <div className='flex space-x-2'>
                        <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-[4px] minlg:h-[46px] text-xl  text-[#3869F1]"> 5</p>
                        <h1 className={`${cardFiveActive ? "text-white  " : " text-[#3869F1]"} minlg:text-xl lg:text-md group-hover:text-white flex items-center text-[#3869F1]`}>{t<string>("StartsEasilyItem_5_title")}</h1>
                    </div>

                    <p className={`${cardFiveActive ? "text-white  " : " text-[#3869F1]"}text-sm font-light tracking-wide text-left group-hover:text-white  leading-relaxed  text-[#424242] `}> {t<string>("StartsEasilyItem_5_text")}</p>
                </div>
                <CurveArrow className="h-4 mt-40 lg:h-2 "/>
                <div
                    className='shadow-xl flex flex-col   justify-between shadow-[#DAE4FB] rounded-lg space-y-5 w-1/3 h-[260px]  lg:h-[320px] bg-[#DFDEDF] cursor-not-allowed     lg:p-5  minlg:p-8 '>
                    <div className='space-y-5'>
                        <div className='flex space-x-2 '>
                            <p className=" bg-[#DAE4FB] flex justify-center items-center lg:w-[38px] lg:h-[38px] minlg:w-[46px] rounded-[4px] minlg:h-[46px] text-xl  text-[#3869F1]">6</p>
                            <h1 className=' minlg:text-xl lg:text-md flex items-center text-[#3869F1]'>{t<string>("StartsEasilyItem_6_title")}</h1>
                        </div>
                        <div>
                            <p className='text-sm font-light tracking-wide text-left leading-relaxed  text-[#424242] '> {t<string>("StartsEasilyItem_6_text")}</p>
                        </div>
                    </div>
                    <p className=' tracking-wide text-[#9B141E] leading-relaxed   text-md'> {t<string>("UPCOMING!")}</p>
                </div>

            </div>


        </div>


    )


}

export default QuickGuide