import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'
// import Redevlogo  from '../../../assets/Redevlogo.png'
import GradiantUnderline from '../GradiantUnderline/GradiantUnderline'
import {companyPageNavItems, MainPageNavItems} from './NavConstants'
import HamburgerIcon from '../../../assets/icons/HamburgerIcon'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import Redevlogo from '../../../assets/img/Redevlogo'


const NavBar = () => {
    const {t, i18n} = useTranslation()


    const [openHamMenu, setOpenHamMenu] = useState<boolean>(false)

    const navigate = useNavigate()
    const location = useLocation()

    const [withNavigation, setWithNavigation] = useState<boolean>(true)
    const [navBarSecStyle, setNavBarSecStyle] = useState<boolean>(false)
    const handleChangeLang = (lng: string) => {
        i18n.changeLanguage(lng)
        localStorage.setItem('lng', lng)
    }
    const handleSwichPages = () => {

        if (location.pathname === '/companies') {
            navigate('/candidates')
        }
        if (location.pathname === '/candidates') {
            navigate('/companies')
        }


    }

    useEffect(() => {
        if (location.pathname.includes('article')) {
            setWithNavigation(false)
        } else {
            setWithNavigation(true)
        }
    }, [location.pathname])


    const onScroll = () => {
        const scrollPos = window.scrollY

        if (scrollPos >= 150) {
            setNavBarSecStyle(true)
        } else {
            setNavBarSecStyle(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    },)


    return (
        <nav id='navbar'
             className={`top-0 left-0 right-0  min2xl:bg-white z-40 transition-all h-[4rem]  duration-300  sm:pl-[15px]${!withNavigation && 'static'} ${navBarSecStyle ? 'bg-white shadow-md fixed' : ' lg:absolute '}`}>
            {/* <div className=' flex justify-between pt-5  font-semibold  max-w-7xl mx-auto '> */}
            <div className='flex  pr-4 justify-between pt-5  font-semibold text-sm leading-5 max-w-7xl mx-auto'>

                <ul className='flex  flex-row items-center  space-x-10 xl:gap-x-2 '>
                    <Link to='/'>
                        <li className='sm:ml-4'><Redevlogo/></li>
                    </Link>

                    {withNavigation && (
                        location.pathname === '/candidates' ?
                            MainPageNavItems.map((item, index) => (
                                <li key={index + 1} className='lg:hidden minlg:block'>
                                    <a href={item.href}>{t<string>(item.title)}</a>
                                </li>
                            )) :
                            companyPageNavItems.map((item, index) => (
                                <li key={index + 1} className=' lg:hidden minlg:block'>
                                    <a href={item.href}>{t<string>(item.title)}</a>
                                </li>
                            )))
                    }
                </ul>
                <div className='flex flex-row items-center gap-x-8 items-center md:w-fit'>
                    <div className='flex flex-row items-center gap-x-2 w-full lg:hidden '>
                        <div
                            className={`w-fit flex flex-col items-center justify-center text-sm ${i18n.language === 'en' ? 'text-[#202334] font-bold' : 'text-[#9A9A9A] font-medium'}`}>
                            <button onClick={() => handleChangeLang('en')} type='button'>EN</button>
                            <GradiantUnderline w="w-[25px]" isVisible={i18n.language === 'en'}/>
                        </div>
                        <div
                            className={`w-fit flex flex-col items-center justify-center text-sm mx-4 ${i18n.language === 'de' ? 'text-[#202334] font-bold' : 'text-[#9A9A9A] font-medium'}`}>
                            <button onClick={() => handleChangeLang('de')} type='button'>DE</button>
                            <GradiantUnderline w="w-[25px]" isVisible={i18n.language === 'de'}/>
                        </div>
                    </div>

                    {withNavigation ? <button onClick={handleSwichPages} type='button'
                                              className='bg-[#202334] rounded-lg py-2 px-4 min-w-[9.3rem] whitespace-nowrap text-white lg:hidden '>
                            {location.pathname === '/candidates' ? t<string>('For Companies') : t<string>('For Candidates')}
                        </button> :
                        <a href='https://dashboard.redev.at' className='lg:hidden minlg:block'>
                            <button className='bg-[#202334] rounded-lg h-9 w-36 text-white '>
                                {t<string>('get started')}
                            </button>
                        </a>
                    }
                </div>
                <button onClick={() => setOpenHamMenu(true)} type='button'
                        className='text-[#202334] mr-6 hidden lg:block'>
                    <HamburgerIcon/>
                </button>
            </div>
            <HamburgerMenu open={openHamMenu} onClose={() => setOpenHamMenu(false)}/>
        </nav>

    )
}

export default NavBar
