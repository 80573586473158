import React, {useState} from 'react'
import {useTranslation} from "react-i18next"

import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from "swiper";

const GuideSlider = () => {
    const [swiper, setSwiper] = useState<any>(null)
    const {t} = useTranslation();

    return (
        <div className='bg-[#eef2ff] pb-4'>
            <div className='mx-2 pt-10  '>
                <div className='flex space-x-60 mb-10'>
                    <div className=' w-[100%] space-y-6'>
                        <h1 className=" text-2xl font-semibold sm:text-center ">{t<string>("guidetitle")}</h1>
                        <p className='text-sm font-light  leading-relaxed   sm:text-center text-[#424242]'>{t<string>("secondText")}</p>
                        <div className='space-x-6 flex justify-center'>
                            <a href='https://dashboard.redev.jobs/' target={"_blank"}>
                                <button
                                    type="submit"
                                    className="  bg-[#3869F1] rounded-[8px] sm:rounded-[8px] sm:text-sm text-white w-28  text-sm  py-2 transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-110 "
                                > {t<string>("get started")}</button>
                            </a>
                            <a href='https://dashboard.redev.jobs/request-demo' target={"_blank"}>
                                <button
                                    type="submit"
                                    className=" bg-black rounded-[8px] sm:rounded-[8px] text-white sm:text-sm  text-sm w-28 py-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 "
                                > {t<string>('try_free_demo')}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='flex items-center h-96 px-2'>
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >

                        <SwiperSlide
                            className={`${swiper?.activeIndex === 3 ? 'bg-[#0047FF]' : ''} hover:bg-[#3869F1] group  min-h-[250px]  mb-20 cursor-pointer group-hover:text-white  rounded-[20px] space-y-5  bg-white box-border h-[210px] p-6 `}>

                            <div className=' flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 1</p>
                                <h1 className=' text-sm group-hover:text-white text-[#3869F1]'>{t<string>("StartsEasilyItem_1_title")}</h1>
                            </div>

                            <p className='text-sm  font-light tracking-wide text-left leading-relaxed text-[#424242] group-hover:text-white'> {t<string>("StartsEasilyItem_1_text")}</p>
                        </SwiperSlide>


                        <SwiperSlide
                            className='  cursor-pointer group hover:bg-[#3869F1] min-h-[250px] rounded-[20px] space-y-5 w-1/3  bg-white box-border  h-[210px]  sm:w-full p-6 '>

                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 2</p>
                                <h1 className=' text-sm group-hover:text-white  text-[#3869F1]'>{t<string>("StartsEasilyItem_2_title")}</h1>
                            </div>

                            <p className='text-sm font-light tracking-wide group-hover:text-white  text-left leading-relaxed  text-[#424242] '> {t<string>("StartsEasilyItem_2_text")}</p>
                        </SwiperSlide>


                        <SwiperSlide
                            className='  group cursor-pointer  hover:bg-[#3869F1] min-h-[250px] rounded-[20px] space-y-5 w-1/3  bg-white box-border   h-[210px]  sm:w-full p-6 '>

                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 3</p>
                                <h1 className='text-sm group-hover:text-white  text-[#3869F1]'>{t<string>("StartsEasilyItem_3_title")}</h1>
                            </div>

                            <p className='text-sm font-light tracking-wide text-left  group-hover:text-white  leading-relaxed  text-[#424242] '>{t<string>("StartsEasilyItem_3_text")}</p>
                        </SwiperSlide>
                        <SwiperSlide
                            className=' hover:bg-[#3869F1] group  cursor-pointer min-h-[250px] group-hover:text-white  rounded-[20px] space-y-5 h-[210px] bg-white box-border     sm:w-full p-6 '>

                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 4</p>
                                <h1 className='text-sm group-hover:text-white  text-[#3869F1]'>{t<string>("StartsEasilyItem_2_title")}</h1>
                            </div>

                            <p className='text-sm font-light group-hover:text-white  tracking-wide text-left leading-relaxed  text-[#424242] '> {t<string>("StartsEasilyItem_4_text")}</p>
                        </SwiperSlide>

                        <SwiperSlide
                            className='  group hover:bg-[#3869F1] min-h-[250px] rounded-[20px] space-y-5 w-1/3  cursor-pointer bg-white box-border   h-[210px]  sm:w-full p-6 '>

                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 5</p>
                                <h1 className=' text-sm group-hover:text-white  text-[#3869F1]'>{t<string>("StartsEasilyItem_5_title")}</h1>
                            </div>

                            <p className='text-sm font-light tracking-wide text-left group-hover:text-white  leading-relaxed  text-[#424242] '> {t<string>("StartsEasilyItem_5_text")}</p>
                        </SwiperSlide>
                        <SwiperSlide
                            className='  rounded-[20px] space-y-5 w-1/3 min-h-[250px] bg-[#DFDEDF] cursor-not-allowed box-border h-[210px] sm:w-full p-6 '>

                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] text-xl  sm:h-[36px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]"> 6</p>
                                <h1 className=' text-sm text-[#3869F1]'>{t<string>("StartsEasilyItem_6_title")}</h1>
                            </div>

                            <p className='text-sm font-light tracking-wide text-left leading-relaxed  text-[#424242] '> {t<string>("StartsEasilyItem_6_title")}</p>
                            <p className=' tracking-wide text-[#9B141E] leading-relaxed   text-xs'> {t<string>("UPCOMING!")}</p>
                        </SwiperSlide>


                    </Swiper></div>
            </div>
        </div>
    )
}

export default GuideSlider