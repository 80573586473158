import React, {useEffect, useState} from "react";
import axios from "axios";
import Arrow from "../../../assets/img/Arrow";
import {Link} from "react-router-dom";

const PressCard = () => {
    const [testData, setTestData] = useState([]);

    useEffect(() => {
        axios
            .get("https://api.redev.jobs/landing/blog/main")
            .then((res) => {
                setTestData(res.data)
            })

            .catch(() => {
            });
    }, []);

    const shortifyText = (text: string) => {
        if
        (text.length > 80) {
            return text.substring(0, 85)
        }
        return text
    }

    return (
        <div
            className="grid grid-cols-12 w-full gap-x-10 md:grid-cols-1 md:items-start md:justify-start md:gap-y-4 ">
            {testData.map((dataItem: any, i: number) => (
                <div
                    key={i}
                    className="col-span-4 bg-white rounded-md drop-shadow-sm shadow-[#2D2D2D] rounded-md cursor-pointer drop-shadow-xl md:w-full "
                >
                    <Link to={`/article/${dataItem?.id}`} className='w-full'>
                        <div className="max-h-[50%]">
                            <img
                                className="rounded-t-md aspect-video w-full h-full object-cover"
                                src={dataItem.coverUrl}
                                alt=""
                            />
                        </div>
                        <div className="mx-4 my-6 flex flex-col justify-between min-h-[170px] ">
                            <div className="">
                                <h5 className=" text-sm md:text-base text-[#3869F1]">
                                    {dataItem.title}
                                </h5>
                                <p className="text-sm minmd:mt-4 md:mt-3 text-[#6b7280] md:text-xs md:hidden">{shortifyText(dataItem.subtitle)}</p>
                            </div>
                            <p className="text-sm minmd:hidden  text-[#6b7280] md:text-x ">{shortifyText(dataItem.subtitle)}</p>

                            <div className="grid grid-cols-12 w-full">
                                <div className="col-span-10 text-[#282120] text-xs">
                                    {dataItem.publishedAtStr}
                                </div>
                                <div className="w-fit col-span-2 flex flex-row items-center justify-between gap-x-2">
                                    <p className="text-xs text-[#3869F1]">
                                        More
                                    </p>
                                    <Arrow
                                        color="#3869F1"
                                        className="w-3 h-3 cursor-pointer rotate-180"
                                    />
                                </div>

                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default PressCard;
