import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Slider from "./Slider";
import MobileSlider from "./MobileSlider";


const Events = () => {

    const {t, i18n} = useTranslation();
    const breakpoint = 767
    const [eventData, setEventData] = useState<any>()
    const [width, setWidth] = React.useState(window.innerWidth);

    useEffect(() => {

        window.addEventListener("resize", () => setWidth(window.innerWidth))
    }, []);


    useEffect(() => {
        axios
            .get("https://api.redev.jobs/landing/event/main")
            .then((res) => {
                setEventData(res.data);
            })
            .catch(() => {
            });
    }, []);
    // console.log(eventData);

    if (eventData) {
        return width < breakpoint ? <MobileSlider images={eventData.images}/>
            : (
                <div className="max-w-7xl mx-auto mt-32 sm:h-screen h-96 mb-32 lg:mb-14 space-y-12 lg:px-4">
                    <div className="flex flex-col  text-center justify-center items-center space-y-4">
                        <div className=" text-4xl sm:text-2xl font-semibold text-[#3869F1]">
                            ReDev <span className="text-[#1B2A3E]"> Events</span>
                        </div>
                        {/*<p className="tracking-wide text-xs">*/}
                        {/*    {t<string>("fourthText")}*/}
                        {/*</p>*/}
                    </div>
                    <div className="grid grid-cols-10 sm:pt-6 relative ">
                        <div className="col-span-4 sm:px-6 box-border h-32  sm:w-[100%]  space-y-10 minmd:pl-4">
                            <div
                                className="text-[#1B2A3E] lg:text-2xl text-3xl font-semibold leading-[50px] md:text-xl sm:text-center  sm:leading-[30px]">
                                {t<string>("fourthText")}
                                {/*{eventData[i18n.language].title} */}
                            </div>
                            <div
                                className="text-md font-light text-[#54627B] lg:text-sm text-justify leading-relaxed  sm:w-[100%] ">
                                {eventData[i18n.language].subtitle}
                            </div>
                        </div>
                        <div className='col-span-6 sm:hidden'>
                            <div
                                className="absolute bg-[#eef2ff] rounded-l-xl h-[20rem] lg:h-[13rem] w-full -right-2/3  mx-auto py-10 ">
                            </div>
                            <div className="absolute col-span-6 w-1/2 minxl:w-full minxl:-right-[40rem] -right-20  ">
                                <Slider images={eventData.images}/>
                            </div>
                        </div>
                    </div>
                </div>

            );
    } else {
        return null;
    }


};

export default Events;
